export const countryCodeList = [
  {
    value: '+82',
    label: '+82 KOR'
  },
  {
    value: '+1',
    label: '+1 USA/CAN'
  },
  {
    value: '+44',
    label: '+44 GBR/JEY/IMN'
  },
  {
    value: '+81',
    label: '+81 JPN'
  },
  {
    value: '+86',
    label: '+86 CHN'
  },
  {
    value: '+886',
    label: '+886 TWN'
  },
  {
    value: '+852',
    label: '+852 HKG'
  },
  {
    value: '+65',
    label: '+65 SGP'
  },
  {
    value: '+66',
    label: '+66 THA'
  },
  {
    value: '+84',
    label: '+84 VNM'
  },
  {
    value: '+63',
    label: '+63 PHL'
  },
  {
    value: '+49',
    label: '+49 DEU'
  },
  {
    value: '+33',
    label: '+33 FRA'
  },
  {
    value: '+34',
    label: '+34 ESP'
  },
  {
    value: '+61',
    label: '+61 AUS/CXR/CCK'
  },
  {
    value: '+64',
    label: '+64 NZL/ATA'
  },
  {
    value: '+91',
    label: '+91 IND'
  },
  {
    value: '+31',
    label: '+31 NLD'
  },
  {
    value: '+39',
    label: '+39 ITA/VAT'
  },
  {
    value: '+60',
    label: '+60 MYS'
  },
  {
    value: '+62',
    label: '+62 IDN'
  },
  {
    value: '+7',
    label: '+7 RUS/KAZ'
  },
  {
    value: '+976',
    label: '+976 MNG'
  },
  {
    value: '+1 242',
    label: '+1 242 BHS'
  },
  {
    value: '+1 246',
    label: '+1 246 BRB'
  },
  {
    value: '+1 264',
    label: '+1 264 AIA'
  },
  {
    value: '+1 268',
    label: '+1 268 ATG'
  },
  {
    value: '+1 284',
    label: '+1 284 VGB'
  },
  {
    value: '+1 340',
    label: '+1 340 VIR'
  },
  {
    value: '+1 345',
    label: '+1 345 CYM'
  },
  {
    value: '+1 441',
    label: '+1 441 BMU'
  },
  {
    value: '+1 473',
    label: '+1 473 GRD'
  },
  {
    value: '+1 649',
    label: '+1 649 TCA'
  },
  {
    value: '+1 664',
    label: '+1 664 MSR'
  },
  {
    value: '+1 670',
    label: '+1 670 MNP'
  },
  {
    value: '+1 671',
    label: '+1 671 GUM'
  },
  {
    value: '+1 684',
    label: '+1 684 ASM'
  },
  {
    value: '+1 721',
    label: '+1 721 SXM'
  },
  {
    value: '+1 758',
    label: '+1 758 LCA'
  },
  {
    value: '+1 767',
    label: '+1 767 DMA'
  },
  {
    value: '+1 784',
    label: '+1 784 VCT'
  },
  {
    value: '+1 787',
    label: '+1 787 PRI'
  },
  {
    value: '+1 809',
    label: '+1 809 DOM'
  },
  {
    value: '+1 829',
    label: '+1 829 DOM'
  },
  {
    value: '+1 849',
    label: '+1 849 DOM'
  },
  {
    value: '+1 868',
    label: '+1 868 TTO'
  },
  {
    value: '+1 869',
    label: '+1 869 KNA'
  },
  {
    value: '+1 876',
    label: '+1 876 JAM'
  },
  {
    value: '+1 939',
    label: '+1 939 PRI'
  },
  {
    value: '+20',
    label: '+20 EGY'
  },
  {
    value: '+211',
    label: '+211 SSD'
  },
  {
    value: '+212',
    label: '+212 MAR/ESH'
  },
  {
    value: '+213',
    label: '+213 DZA'
  },
  {
    value: '+216',
    label: '+216 TUN'
  },
  {
    value: '+218',
    label: '+218 LBY'
  },
  {
    value: '+220',
    label: '+220 GMB'
  },
  {
    value: '+221',
    label: '+221 SEN'
  },
  {
    value: '+222',
    label: '+222 MRT'
  },
  {
    value: '+223',
    label: '+223 MLI'
  },
  {
    value: '+224',
    label: '+224 GIN'
  },
  {
    value: '+225',
    label: '+225 CIV'
  },
  {
    value: '+226',
    label: '+226 BFA'
  },
  {
    value: '+227',
    label: '+227 NER'
  },
  {
    value: '+228',
    label: '+228 TGO'
  },
  {
    value: '+229',
    label: '+229 BEN'
  },
  {
    value: '+230',
    label: '+230 MUS'
  },
  {
    value: '+231',
    label: '+231 LBR'
  },
  {
    value: '+232',
    label: '+232 SLE'
  },
  {
    value: '+233',
    label: '+233 GHA'
  },
  {
    value: '+234',
    label: '+234 NGA'
  },
  {
    value: '+235',
    label: '+235 TCD'
  },
  {
    value: '+236',
    label: '+236 CAF'
  },
  {
    value: '+237',
    label: '+237 CMR'
  },
  {
    value: '+238',
    label: '+238 CPV'
  },
  {
    value: '+239',
    label: '+239 STP'
  },
  {
    value: '+240',
    label: '+240 GNQ'
  },
  {
    value: '+241',
    label: '+241 GAB'
  },
  {
    value: '+242',
    label: '+242 COG'
  },
  {
    value: '+243',
    label: '+243 COD'
  },
  {
    value: '+244',
    label: '+244 AGO'
  },
  {
    value: '+245',
    label: '+245 GNB'
  },
  {
    value: '+246',
    label: '+246 DGA'
  },
  {
    value: '+247',
    label: '+247 ASC'
  },
  {
    value: '+248',
    label: '+248 SYC'
  },
  {
    value: '+249',
    label: '+249 SDN'
  },
  {
    value: '+250',
    label: '+250 RWA'
  },
  {
    value: '+251',
    label: '+251 ETH'
  },
  {
    value: '+252',
    label: '+252 SOM'
  },
  {
    value: '+253',
    label: '+253 DJI'
  },
  {
    value: '+254',
    label: '+254 KEN'
  },
  {
    value: '+255',
    label: '+255 TZA'
  },
  {
    value: '+256',
    label: '+256 UGA'
  },
  {
    value: '+257',
    label: '+257 BDI'
  },
  {
    value: '+258',
    label: '+258 MOZ'
  },
  {
    value: '+260',
    label: '+260 ZMB'
  },
  {
    value: '+261',
    label: '+261 MDG'
  },
  {
    value: '+262',
    label: '+262 REU/MYT'
  },
  {
    value: '+263',
    label: '+263 ZWE'
  },
  {
    value: '+264',
    label: '+264 NAM'
  },
  {
    value: '+265',
    label: '+265 MWI'
  },
  {
    value: '+266',
    label: '+266 LSO'
  },
  {
    value: '+267',
    label: '+267 BWA'
  },
  {
    value: '+268',
    label: '+268 SWZ'
  },
  {
    value: '+269',
    label: '+269 COM'
  },
  {
    value: '+27',
    label: '+27 ZAF'
  },
  {
    value: '+290',
    label: '+290 SHN'
  },
  {
    value: '+291',
    label: '+291 ERI'
  },
  {
    value: '+297',
    label: '+297 ABW'
  },
  {
    value: '+298',
    label: '+298 FRO'
  },
  {
    value: '+299',
    label: '+299 GRL'
  },
  {
    value: '+30',
    label: '+30 GRC'
  },
  {
    value: '+32',
    label: '+32 BEL'
  },
  {
    value: '+350',
    label: '+350 GIB'
  },
  {
    value: '+351',
    label: '+351 PRT'
  },
  {
    value: '+352',
    label: '+352 LUX'
  },
  {
    value: '+353',
    label: '+353 IRL'
  },
  {
    value: '+354',
    label: '+354 IS'
  },
  {
    value: '+355',
    label: '+355 ALB'
  },
  {
    value: '+356',
    label: '+356 MLT'
  },
  {
    value: '+357',
    label: '+357 CYP'
  },
  {
    value: '+358',
    label: '+358 FIN'
  },
  {
    value: '+359',
    label: '+359 BGR'
  },
  {
    value: '+36',
    label: '+36 HUN'
  },
  {
    value: '+370',
    label: '+370 LTU'
  },
  {
    value: '+371',
    label: '+371 LVA'
  },
  {
    value: '+372',
    label: '+372 EST'
  },
  {
    value: '+373',
    label: '+373 MDA'
  },
  {
    value: '+374',
    label: '+374 ARM'
  },
  {
    value: '+375',
    label: '+375 BLR'
  },
  {
    value: '+376',
    label: '+376 AND'
  },
  {
    value: '+377',
    label: '+377 MCO'
  },
  {
    value: '+378',
    label: '+378 SMR'
  },
  {
    value: '+380',
    label: '+380 UKR'
  },
  {
    value: '+381',
    label: '+381 SRB'
  },
  {
    value: '+382',
    label: '+382 MNE'
  },
  {
    value: '+385',
    label: '+385 HRV'
  },
  {
    value: '+386',
    label: '+386 SVN'
  },
  {
    value: '+387',
    label: '+387 BIH'
  },
  {
    value: '+389',
    label: '+389 MKD'
  },
  {
    value: '+40',
    label: '+40 ROU'
  },
  {
    value: '+41',
    label: '+41 CHE'
  },
  {
    value: '+420',
    label: '+420 CZE'
  },
  {
    value: '+421',
    label: '+421 SVK'
  },
  {
    value: '+423',
    label: '+423 LIE'
  },
  {
    value: '+43',
    label: '+43 AUT'
  },
  {
    value: '+45',
    label: '+45 DNK'
  },
  {
    value: '+46',
    label: '+46 SWE'
  },
  {
    value: '+47',
    label: '+47 NOR/SJM'
  },
  {
    value: '+48',
    label: '+48 POL'
  },
  {
    value: '+500',
    label: '+500 FLK'
  },
  {
    value: '+501',
    label: '+501 BLZ'
  },
  {
    value: '+502',
    label: '+502 GTM'
  },
  {
    value: '+503',
    label: '+503 SLV'
  },
  {
    value: '+504',
    label: '+504 HND'
  },
  {
    value: '+505',
    label: '+505 NIC'
  },
  {
    value: '+506',
    label: '+506 CRC'
  },
  {
    value: '+507',
    label: '+507 PAN'
  },
  {
    value: '+508',
    label: '+508 SPM'
  },
  {
    value: '+509',
    label: '+509 HTI'
  },
  {
    value: '+51',
    label: '+51 PER'
  },
  {
    value: '+52',
    label: '+52 MEX'
  },
  {
    value: '+53',
    label: '+53 CUB'
  },
  {
    value: '+54',
    label: '+54 ARG'
  },
  {
    value: '+55',
    label: '+55 BRA'
  },
  {
    value: '+56',
    label: '+56 CHL'
  },
  {
    value: '+57',
    label: '+57 COL'
  },
  {
    value: '+58',
    label: '+58 VEN'
  },
  {
    value: '+590',
    label: '+590 MAF/BLM/GLP'
  },
  {
    value: '+591',
    label: '+591 BOL'
  },
  {
    value: '+592',
    label: '+592 GUY'
  },
  {
    value: '+593',
    label: '+593 ECU'
  },
  {
    value: '+594',
    label: '+594 GUF'
  },
  {
    value: '+595',
    label: '+595 PRY'
  },
  {
    value: '+596',
    label: '+596 MTQ'
  },
  {
    value: '+597',
    label: '+597 SUR'
  },
  {
    value: '+598',
    label: '+598 URY'
  },
  {
    value: '+599',
    label: '+599 ANT'
  },
  {
    value: '+670',
    label: '+670 TLS'
  },
  {
    value: '+672',
    label: '+672 NFK/ATA'
  },
  {
    value: '+673',
    label: '+673 BRN'
  },
  {
    value: '+674',
    label: '+674 NRU'
  },
  {
    value: '+675',
    label: '+675 PNG'
  },
  {
    value: '+676',
    label: '+676 TON'
  },
  {
    value: '+677',
    label: '+677 SLB'
  },
  {
    value: '+678',
    label: '+678 VUT'
  },
  {
    value: '+679',
    label: '+679 FJI'
  },
  {
    value: '+680',
    label: '+680 PLW'
  },
  {
    value: '+681',
    label: '+681 WLF'
  },
  {
    value: '+682',
    label: '+682 COK'
  },
  {
    value: '+683',
    label: '+683 NIU'
  },
  {
    value: '+685',
    label: '+685 WSM'
  },
  {
    value: '+686',
    label: '+686 KIR'
  },
  {
    value: '+687',
    label: '+687 NCL'
  },
  {
    value: '+688',
    label: '+688 TUV'
  },
  {
    value: '+689',
    label: '+689 PYF'
  },
  {
    value: '+690',
    label: '+690 TKL'
  },
  {
    value: '+691',
    label: '+691 FSM'
  },
  {
    value: '+692',
    label: '+692 MHL'
  },
  {
    value: '+850',
    label: '+850 PRK'
  },
  {
    value: '+853',
    label: '+853 MAC'
  },
  {
    value: '+855',
    label: '+855 KHM'
  },
  {
    value: '+856',
    label: '+856 LAO'
  },
  {
    value: '+870',
    label: '+870 PCN'
  },
  {
    value: '+880',
    label: '+880 BGD'
  },
  {
    value: '+90',
    label: '+90 TUR'
  },
  {
    value: '+92',
    label: '+92 PAK'
  },
  {
    value: '+93',
    label: '+93 AFG'
  },
  {
    value: '+94',
    label: '+94 LKA'
  },
  {
    value: '+95',
    label: '+95 MMR'
  },
  {
    value: '+960',
    label: '+960 MDV'
  },
  {
    value: '+961',
    label: '+961 LBN'
  },
  {
    value: '+962',
    label: '+962 JOR'
  },
  {
    value: '+963',
    label: '+963 SYR'
  },
  {
    value: '+964',
    label: '+964 IRQ'
  },
  {
    value: '+965',
    label: '+965 KWT'
  },
  {
    value: '+966',
    label: '+966 SAU'
  },
  {
    value: '+967',
    label: '+967 YEM'
  },
  {
    value: '+968',
    label: '+968 OMN'
  },
  {
    value: '+970',
    label: '+970 PSE'
  },
  {
    value: '+971',
    label: '+971 ARE'
  },
  {
    value: '+972',
    label: '+972 ISR'
  },
  {
    value: '+973',
    label: '+973 BHR'
  },
  {
    value: '+974',
    label: '+974 QAT'
  },
  {
    value: '+975',
    label: '+975 BTN'
  },
  {
    value: '+977',
    label: '+977 NPL'
  },
  {
    value: '+98',
    label: '+98 IRN'
  },
  {
    value: '+992',
    label: '+992 TJK'
  },
  {
    value: '+993',
    label: '+993 TKM'
  },
  {
    value: '+994',
    label: '+994 AZE'
  },
  {
    value: '+995',
    label: '+995 GEO'
  },
  {
    value: '+996',
    label: '+996 KGZ'
  },
  {
    value: '+998',
    label: '+998 UZB'
  }
];
