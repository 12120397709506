


























































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import {reservationModule} from '@/store';
import {Notify} from 'quasar';
import {countryCodeList} from '@/shared/utils/countryCode';
import {EventBus} from '@/config/config';
import moment from 'moment';

@Component({
    components: {
        'alert-modal': () => import('@/components/popup/AlertModal.vue'),
        'reserve-before-check': () => import('@/components/popup/ReserveBeforeCheck.vue')
    }
})
export default class ReserveInfoModal extends mixins(DataFormat) {
    @Prop()
    public place!: any;
    @Prop()
    public reserveParams!: {
        date: string;
        peopleCount: number;
        peopleDetailCount: object;
        time: string;
        tableType: string;
        requestText: string;
        listBuyOption: any;
        calendarTableType: string;
        listTime: any;
        name: string;
        phone: string;
        email: string;
        countryCode: string;
        totalPrice: number;
    };
    @Prop()
    public selectLang!: any;

    public $refs!: {
        scrollWarp: HTMLElement;
    }

    public isModalClose: boolean = false;
    public user: string = '';
    public phoneNum: any = '';
    public requestNum: any = '';
    public radioChoice: any = '내국인';
    // 인증번호 발송 여부
    public isSendNumber: boolean = false;
    // 인증번호 만료
    public requestNumExpire: boolean = false;
    // 인증번호 확인 여부
    public requestComplete: boolean = false;
    public time: any = {
        minute: 9,
        second: 59
    }
    public requestSendText: string = '인증번호 발송';
    public email: string = '';
    public countryCode: any = '국가번호';
    public countryList: any = countryCodeList;
    public height: number = 120;
    public allergyHeight: number = 120;
    public errorMessage: string = '';
    public timeInterval: any = null;
    public selectShow: boolean = false;
    public topElHeight: number = 0;
    public loading: boolean = false;
    public ReserveBeforeCheckStatus: boolean = false;

    public modalOpened() {
        const el: any = document.querySelector('.certification-area');
        this.topElHeight = el.scrollHeight;
        this.fetchReservationAddInfo();
    }

    public back() {
        this.$modal.hide('reserveInfo');
        this.user = '';
        this.phoneNum = '';
        this.email = '';
        this.requestComplete = false;
    }

    //매장 추가 정보 조회
    public fetchReservationAddInfo() {
        reservationModule
            .fetchReservationAddInfo({place_id: this.place.id})
            .then((res) => {
                if (res.data) {
                    // res.data.filter((row: any) => {
                    //     if (row.title === '알러지') {
                    //         this.allergyKey = row.key;
                    //     }
                    // })
                }
            });
    }

    public radioClick(e: any) {
        if (this.requestComplete) {
            e.preventDefault();
        }
    }

    //인증번호 발송
    public requestSendClick() {
        if (this.requestComplete) {
            return;
        }
        if (this.requestSendCheck) {
            this.isSendNumber = false;
            const params = {
                receive_type: 'sms',
                receive_to: this.phoneNum,
                place_id: this.place.id
            };

            let message = 'SMS로 인증번호가 발송되었습니다.';
            if (this.radioChoice === '외국인') {
                params.receive_type = 'email';
                params.receive_to = this.email;
                message = '이메일로 인증번호가 발송되었습니다.';
            }
            reservationModule
                .postRequestNumSend(params)
                .then((res) => {
                    if (res.data) {
                        clearInterval(this.timeInterval);
                        this.time.minute = 9;
                        this.time.second = 59;
                        Notify.create({
                            message
                        });
                        this.requestSendText = '인증번호 재발송';
                        this.isSendNumber = true;
                        this.validTimeCheck();
                    }
                });
        }
    }

    public validTimeCheck() {
        this.timeInterval = setInterval(() => {
            this.time.second = this.time.second - 1;
            if (this.time.second === -1) {
                this.time.second = 59;
                this.time.minute = this.time.minute - 1;
            }
            if (this.time.second === 0 && this.time.minute === 0) {
                Notify.create({
                    message: '인증번호 유효시간이 경과했습니다.'
                });
                clearInterval(this.timeInterval);
                this.requestNumExpire = true;
                this.isSendNumber = false;
            }
        }, 1000);
    }


    //다음버튼 활성 여부
    public getNextDisable() {
        return !(this.requestComplete && this.user !== '');
    }

    public ReserveBeforeCheckUpdate() {
        this.reservationRequest();
    }

    public submit() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.reserveParams.name = this.user;
        this.reserveParams.phone = this.phoneNum;
        this.reserveParams.email = this.email;
        this.reserveParams.countryCode = this.countryCode.value;

        if (this.reservationAddInfo.length === 0) {
            if (this.place.reservation_method === 'option') {
                this.cartPayment();
            } else {
                if (this.place.reserve_checklist.length > 0) {
                    this.ReserveBeforeCheckStatus = true;
                    this.loading = false;
                } else {
                    this.reservationRequest();
                }
            }
        } else {
            this.$modal.show('reserveAddInfo');
            this.loading = false;
        }
    }

    //카트 생성
    public cartPayment() {
        const {time, listBuyOption, tableType, peopleCount, name, phone, email, countryCode, requestText} = this.reserveParams;
        const date = moment(this.reserveParams.date).format('YYYY-MM-DD');

        const cartArgs: any = {
            date: moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss'),
            buy_option: JSON.stringify(listBuyOption),
            person: peopleCount,
            name,
            phone,
            table_type: tableType,
            is_foreigner: 0,
            message: requestText
        };

        // 외국인 정보 추가
        if (email) {
            cartArgs.is_foreigner = 1;
            cartArgs.email = email;
            cartArgs.country_code = countryCode;
        }

        //인원 구분 데이터 병합
        if (this.reserveParams.peopleDetailCount) {
            Object.entries(this.reserveParams.peopleDetailCount).forEach(([key, value]) => {
                cartArgs[`person_${key}`] = value;
            });
        }

        reservationModule
            .fetchCart(cartArgs)
            .then((res) => {
                if (!res.status) {
                    this.errorMessage = res.error.message;
                    EventBus.$emit('errorMessage', this.errorMessage);
                    this.$modal.show('alert');
                } else {
                    this.$router.push({name: 'payment', params: {cartId: res.data.id}});
                    document.querySelector('html' as any).classList.remove('open-modal');
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    // 일반/대기 예약 하기
    public reservationRequest() {
        const cartDate: any = this.reserveParams.date + ' ' + this.reserveParams.time;
        let payload: any = {
            date: moment(cartDate).format('YYYY-MM-DD HH:mm:ss'),
            message: this.reserveParams.requestText,
            person: this.reserveParams.peopleCount,
            place_id: this.place.id,
            is_foreigner: 0,
            phone: this.reserveParams.phone,
            name: this.reserveParams.name
        };
        // 외국인 정보 추가
        if (this.reserveParams.email) {
            payload.is_foreigner = 1;
            payload.email = this.reserveParams.email;
            payload.country_code = this.reserveParams.countryCode;
        }
        //인원 구분 데이터 병합
        if (this.reserveParams.peopleDetailCount) {
            Object.entries(this.reserveParams.peopleDetailCount).forEach(([key, value]) => {
                payload[`person_${key}`] = value;
            });
        }
        reservationModule
            .fetchReservationRegister(payload)
            .then((res) => {
                console.log(res);
                if (!res.status) {
                    this.errorMessage = res.error.message;
                    EventBus.$emit('errorMessage', this.errorMessage);
                    this.$modal.show('alert');
                } else {
                    this.$router.push({ path: `/payment/result/success?reservation_id=${res.data.id}` });
                    document.querySelector('html' as any).classList.remove('open-modal');
                }
            })
            .finally(() => {
                this.loading = false;
                this.ReserveBeforeCheckStatus = false;
            });
    }

    @Watch('requestNum')
    public certifyConfirm() {
        if (this.isSendNumber && this.requestNum.length === 5) {
            const params = {receive_to: this.phoneNum, certify_number: this.requestNum};

            if (this.radioChoice === '외국인') {
                params.receive_to = this.email;
            }

            reservationModule
                .putRequestNum(params)
                .then((res) => {
                    console.log(res);
                    if (res.error) {
                        Notify.create({
                            message: '인증번호를 확인해 주세요.'
                        });
                        // Notify.create({
                        //     message: res.error.message
                        // });
                    }
                    if (res.data) {
                        Notify.create({
                            message: '인증이 완료되었습니다.'
                        });
                        this.isSendNumber = false;
                        this.requestComplete = true;
                        clearInterval(this.timeInterval);
                    }
                });
        }
    }

    @Watch('requestComplete')
    public requestCompleteUpdate() {
        if (this.requestComplete) {
            setTimeout(() => {
                this.$refs.scrollWarp.scrollTo(0, this.topElHeight - 140);
            }, 350);
        }
    }

    get requestSendCheck() {
        if (this.requestComplete) {
            return false;
        } else {
            if (this.radioChoice === '내국인' && this.phoneNum !== '' && this.user !== '') {
                return true;
            }
            if (this.radioChoice === '외국인' && this.phoneNum !== '' && this.email !== '' && this.countryCode !== '국가번호' && this.user !== '') {
                return true;
            }
        }
        return false;
    }

    get reservationAddInfo() {
        return reservationModule.reservationAddInfo;
    }
    get reserveChecklist() {
        if (this.place && this.place.reserve_checklist && this.place.reserve_checklist.length) {
            const re: any = [];
            this.place.reserve_checklist.filter((row: any, index: any) => {
            re[index] = {};
            re[index].value = row;
            re[index].active = false;
            });
            return re;
        }
        return null;
    }
}
